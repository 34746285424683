class AuthHelper {
  public static ROLE_LIST = [
    'sadmin',
    'admin',
    'operation assistant',
    'manager',
    'supervisor',
    'consignment-assistant',
    'salesperson',
  ]

  static USER_ID = 'user_id'

  // public static allowedIPs = ['118.69.191.99']
  // public static allowedUserIds = ['1', '51', '166', '9090']

  public static PAGE_AUTHORITIES = [
    { page: 'POS', roles: ['operation assistant', 'manager', 'supervisor'] },
    {
      // router index name
      page: 'Payouts',
      roles: ['manager', 'supervisor'],
    },
    {
      page: 'Customers',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Info',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Orders',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Consignments',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Credits',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Payouts',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Reset Passwrod',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'User Roles',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Customer Payout Methods',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Management',
      roles: ['operation assistant', 'manager', 'supervisor'],
    },
    {
      page: 'Employees',
      roles: ['sadmin'],
    },
    {
      page: 'Home sections',
      roles: ['sadmin'],
    },
    {
      page: 'Reports',
      roles: ['supervisor'],
    },
    {
      page: 'Consignments Report',
      roles: ['sadmin'],
    },
    {
      page: 'Payouts Report',
      roles: ['sadmin'],
    },
    {
      page: 'Products Report',
      roles: ['sadmin'],
    },
    {
      page: 'HR',
      roles: ['supervisor'],
    },
    {
      page: 'Shift Table',
      roles: ['supervisor'],
    },
    {
      page: 'Shift Management',
      roles: ['supervisor'],
    },
  ]
  private static USER_EMAIL = 'user_email'
  private static USER_ACCESS_TOKEN = 'user_access_token'
  private static IS_ANONYMOUS = 'is_anonymous'
  private static DISPLAY_LANGUAGE = 'DISPLAY_LANGUAGE'
  private static AUDIO_LANGUAGE = 'AUDIO_LANGUAGE'
  private static notification_mobile_app = 'notification_mobile_app'
  private static notification_email = 'notification_email'
  private static CREDITS = 'CREDITS'
  private static PROVIDER_ID = 'PROVIDER_ID'
  private static anonymous_audio_record = 'anonymous_audio_record'
  private static SUB_PLAN = 'sub_plan'
  private static SUMMARY = 'summary'
  private static QUERY = 'query'
  private static SUB_PLAN_NAME = 'sub_plan_name'

  public static getUserId(): string {
    return localStorage.getItem(this.USER_ID)
  }

  public static setUserId(id) {
    localStorage.setItem(this.USER_ID, id)
  }

  public static getEmail(): string {
    return localStorage.getItem(this.USER_EMAIL)
  }

  public static setEmail(email) {
    localStorage.setItem(this.USER_EMAIL, email)
  }

  public static getAccessToken(showAnonymous = false): string {
    if (!showAnonymous && this.getIsAnonymous() === 'true') {
      return null
    }
    return localStorage.getItem(this.USER_ACCESS_TOKEN)
  }

  public static setAccessToken(data) {
    localStorage.setItem(this.USER_ACCESS_TOKEN, data)
  }

  public static getIsAnonymous(): string {
    return localStorage.getItem(this.IS_ANONYMOUS)
  }

  public static setIsAnonymous(data) {
    localStorage.setItem(this.IS_ANONYMOUS, data)
  }

  public static getDisplayLangCode(): string {
    return localStorage.getItem(this.DISPLAY_LANGUAGE) || 'en'
  }

  public static setDisplayLangCode(data) {
    localStorage.setItem(this.DISPLAY_LANGUAGE, data)
  }

  public static getAudioLangCode(): string {
    let tmp_lang = localStorage.getItem(this.AUDIO_LANGUAGE) || ''
    if (tmp_lang == 'zh') {
      tmp_lang = 'zh_tw'
    }
    return tmp_lang
  }

  public static setAudioLangCode(data) {
    localStorage.setItem(this.AUDIO_LANGUAGE, data)
  }

  public static getCredits(): string {
    return localStorage.getItem(this.CREDITS) || ''
  }

  public static setCredits(data) {
    localStorage.setItem(this.CREDITS, data)
  }

  public static getProviderId(): string {
    return localStorage.getItem(this.PROVIDER_ID) || ''
  }

  public static setProviderId(data) {
    localStorage.setItem(this.PROVIDER_ID, data)
  }

  public static getNotificationEmail(): boolean {
    return localStorage.getItem(this.notification_email) === 'true'
  }

  public static setNotificationEmail(isEnable: boolean) {
    localStorage.setItem(this.notification_email, isEnable.toString())
  }

  public static getNotificationMobileApp(): boolean {
    return localStorage.getItem(this.notification_mobile_app) === 'true'
  }

  public static setNotificationMobileApp(isEnable: boolean) {
    localStorage.setItem(this.notification_mobile_app, isEnable.toString())
  }

  public static setAnonymousAudioRecord(data) {
    localStorage.setItem(this.anonymous_audio_record, data)
  }

  public static getAnonymousAudioRecord(): string {
    return localStorage.getItem(this.anonymous_audio_record)
  }

  public static isPaidUser(): boolean {
    const name = this.getSubscriptionUid()
    return name != null && name !== 'free' && name !== 'sub_basic'
  }

  public static isUsedSummary(): boolean {
    return localStorage.getItem(this.SUMMARY) == 'true'
  }

  public static setUsedSummary(data: boolean) {
    localStorage.setItem(this.SUMMARY, data.toString())
  }

  public static isUsedQuery(): boolean {
    return localStorage.getItem(this.QUERY) == 'true'
  }

  public static setUsedQuery(data: boolean) {
    localStorage.setItem(this.QUERY, data.toString())
  }

  public static getSubscriptionUid(): string {
    return localStorage.getItem(this.SUB_PLAN)
  }

  public static setSubscriptionUid(data) {
    localStorage.setItem(this.SUB_PLAN, data)
  }

  public static getSubscriptionPlanName(): string {
    return localStorage.getItem(this.SUB_PLAN_NAME)
  }

  public static setSubscriptionPlanName(data) {
    localStorage.setItem(this.SUB_PLAN_NAME, data)
  }

  public static getUserName(): string {
    return localStorage.getItem('name')
  }

  public static isRoleExist(): boolean {
    // console.log(localStorage.getItem('roles'))
    return localStorage.getItem('roles')?.length > 0
  }

  public static userLogin(result) {
    if (result == null) {
      return
    }
    this.setUserId(result.id)
    this.setEmail(result.email)
    this.setAccessToken(result.access_token)
    this.setIsAnonymous(result.is_anonymous)
    this.setDisplayLangCode(result.display_language)
    this.setAudioLangCode(result.audio_language)
    this.setNotificationEmail(result.notification_email)
    this.setNotificationMobileApp(result.notification_mobile_app)
    this.setCredits(result.credits)
    if (result.subscription != null) {
      this.setSubscriptionUid(result.subscription.uid)
      this.setSubscriptionPlanName(result.subscription.name)
    }
    // console.log('userLogin - ok')
    // console.log('this.getAccessToken():' + this.getAccessToken())
  }

  public static userLogOut() {
    // console.log('userLogOut')
    localStorage.removeItem(AuthHelper.CREDITS)
    localStorage.removeItem(AuthHelper.USER_ID)
    localStorage.removeItem(AuthHelper.USER_EMAIL)
    localStorage.removeItem(AuthHelper.USER_ACCESS_TOKEN)
    localStorage.removeItem(AuthHelper.IS_ANONYMOUS)
    localStorage.removeItem(AuthHelper.notification_email)
    localStorage.removeItem(AuthHelper.notification_mobile_app)
    localStorage.removeItem(AuthHelper.SUB_PLAN)
    localStorage.removeItem(AuthHelper.SUB_PLAN_NAME)
    localStorage.removeItem(AuthHelper.DISPLAY_LANGUAGE)
    localStorage.removeItem(AuthHelper.AUDIO_LANGUAGE)
    localStorage.removeItem(AuthHelper.anonymous_audio_record)
    localStorage.removeItem(AuthHelper.SUMMARY)
    localStorage.removeItem(AuthHelper.QUERY)
  }

  public static guard(page) {
    // console.log(page)
    // console.log(localStorage.getItem('roles'))
    if (AuthHelper.isAuthorized(page)) {
      return true
    }
    location.replace('/#/')
    return false
  }

  public static isAuthorized(page) {
    const currentRoles = AuthHelper.getRoles()
    if (page == 'Reports' && currentRoles.includes('operation assistant')) {
      return false
    }

    const authorizedRoles = AuthHelper.PAGE_AUTHORITIES.find(
      (p) => p.page === page,
    )
    if (authorizedRoles == null) {
      return true
    }

    return AuthHelper.isRoleIn(authorizedRoles.roles)
  }

  // TODO: fix many roles
  public static getRole(): string {
    return (localStorage.getItem('roles') || '')
      .split(',')
      .find((r) => r != 'admin')
  }

  public static getRoles(): string[] {
    return (localStorage.getItem('roles') || '').split(',')
  }

  public static isRoleIn(role: string | string[]): boolean {
    // console.log('isRoleIn')
    if (role == null) {
      return true
    }
    const currentRoles = AuthHelper.getRoles()
    if (currentRoles == null || currentRoles.length === 0) {
      return false
    }

    if (currentRoles.includes('sadmin')) {
      return true
    }
    if (Array.isArray(role)) {
      for (const r of role) {
        if (currentRoles.includes(r)) {
          return true
        }
      }
    } else {
      return currentRoles.includes(role)
    }
  }

  public static isSupervisor(): boolean {
    return AuthHelper.isRoleIn([
      'sadmin',
      'supervisor',
      'operation assistant',
      'manager',
    ])
  }

  public static setRoles(roles) {
    localStorage.setItem(
      'roles',
      roles
        .map((r) => r.name)
        .filter((r) => r != 'admin')
        .join(','),
    )
  }
}

export default AuthHelper

import axios from 'axios'
import express, { Request, Response } from 'express'

export default async function setPreferredCloudStorage(): Promise<string> {
  try {
    // Fetch the continent code and country information
    const response = await axios.get(
      'https://pro.ip-api.com/json?fields=country,continent,continentCode,countryCode&key=lqQoJ0CYq3fguF3',
    )
    // console.log('response:', response)
    const data = response.data
    const continentCode: string = data.continentCode
    const country: string = data.country
    const countryCode: string = data.countryCode

    // Determine the preferred cloud storage based on the continent code
    let cloudStorage = 'soundtype-sg'
    switch (continentCode) {
      case 'AF': // Africa
      case 'EU': // Europe
        cloudStorage = 'soundtype-euro'
        break
      case 'AN': // Antarctica
      case 'AS': // Asia
      case 'OC': // Oceania
        cloudStorage = 'soundtype-sg'
        break
      case 'NA': // North America
      case 'SA': // South America
        cloudStorage = 'soundtype-us'
        break
      default:
        cloudStorage = 'soundtype-us'
    }

    // Set continent code and country (mock SharedPreferencesHelper functionality)
    localStorage.setItem('continent_code', continentCode)
    localStorage.setItem('country', country)
    localStorage.setItem('country_code', countryCode)
    localStorage.setItem('default_cloud_storage_region', cloudStorage)

    return cloudStorage
  } catch (error) {
    console.error('Error fetching location data:', error)
    return 'soundtype-us' // Default cloud storage in case of error
  }
}

// Mock implementations of SharedPreferencesHelper methods
async function setContinentCode(continentCode: string): Promise<void> {
  localStorage.setItem('continentCode', continentCode)
}

async function setCountry(country: string): Promise<void> {
  localStorage.setItem('country', country)
}

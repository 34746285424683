import { ApiContext } from '../../types'
import { AuthHelper } from '@/api_client'
import { deserializeAudio } from '../serializers/audio'
import { deserializePurchase } from '@/api_client/api/serializers/purchase'

export default async function getPurchaseBySessionId(
  { client, config }: ApiContext,
  session_id: string,
) {
  const response = await client.purchases.stripeSession(
    AuthHelper.getAccessToken(),
    session_id,
  )
  if (response.isSuccess()) {
    const success = response.success()
    return success
  } else {
    console.log('getUsers: fail')
    throw response.fail()
  }
}

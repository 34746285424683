import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { CoreuiVue } from '@coreui/vue-pro'
import { CIcon } from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import ScClient from '@/api_client/ScClient'
import vSelect from 'vue-select'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import filters from '@/helpers/filters'
import money from 'v-money3'
import { createI18n } from 'vue-i18n'
import { messages } from '@/i18n/messags'
import CoreuiVue from '@coreui/vue-pro'
import axios from 'axios'
import 'vue-select/dist/vue-select.css'
import { AuthHelper } from '@/api_client'
import { createGtm } from '@gtm-support/vue-gtm'

const firebaseConfig = {
  apiKey: 'AIzaSyD0oy2DyZVOKmdkK5Nwmadn0ZjYGM71lhg',
  authDomain: 'brief-talk.firebaseapp.com',
  projectId: 'brief-talk',
  storageBucket: 'brief-talk.appspot.com',
  messagingSenderId: '730858755318',
  appId: '1:730858755318:web:743c36c255939f314888d7',
  measurementId: 'G-ZCDCPCDEEW',
}

const lang = AuthHelper.getDisplayLangCode()
const i18n = createI18n({
  legacy: false,
  // something vue-i18n options here ...
  locale: lang, // set locale
  fallbackLocale: 'en', // set fallback locale
  messages,
  silentTranslationWarn: lang == 'en',
  silentFallbackWarn: true,
})

// const currentRole = AuthHelper.isRoleIn('sadmin')

// function isRoleIn(string) {
//   return AuthHelper.isRoleIn(string)
// }

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  )
}

const app = createApp(App)
// console.log(window.location.origin)
app.config.globalProperties.apiClient = new ScClient(
  window.location.origin.includes('https'),
)

// app.config.globalProperties.apiChatClient = new ApiChatClient(
//   window.location.origin.includes('https'),
// )
// if (process.env.NODE_ENV === 'production') {
// TODO: for prod
const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)
app.config.globalProperties.firebase = firebaseApp
app.config.globalProperties.analytics = analytics
// }

app.config.globalProperties.filters = filters

app.config.globalProperties.capitalize = capitalizeFirstLetter
app.config.globalProperties.isMobile = isMobile
app.config.globalProperties.http = axios

app.use(store)
app.use(router)
app.use(
  createGtm({
    id: 'GTM-TN89GCGX', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    vueRouter: router,
    ignoredViews: ['Audio'], // Your views that will be ignored from GTM
  }),
)
app.use(CoreuiVue)
app.use(money)
app.use(i18n)
// app.use(AudioPlayer)

// Vue.use(VueMeta)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('v-select', vSelect)

app.mount('#app')
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
i18n.global.locale = lang
